body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Image css added at 12-07-2022 */
.loginPageContainer {
  background-image: url("../public/images/photos/bg.jpg");
}
.loginPageCard .card {
  background-image: linear-gradient(
      rgba(224, 255, 255, 0.2),
      rgba(255, 258, 255, 0.2)
    ),
    url("../public/images/photos/bg-pattern-2.png");
  background-color: black;
  box-shadow: rgb(255 255 0 / 30%) 0px 14px 28px, #b32d9087 0px 10px 10px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  height: 100%;
}

.cssloader_main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.cssloader_main:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 2;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #4e63ff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.banner-buttons {
  padding: 8px 16px;
  min-width: 140px;
  border: 0px;
  border-radius: 22px;
  background-image: url("../public/images/photos/banner-button-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: transparent;
  /* background-image: linear-gradient(0deg, #4e62ff 0%, #5d0dcb 100%); */
  font-size: 16px;
  color: #fff !important;
  font-family: "Roboto Bold";
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.banner-buttons:hover {
  background-image: url("../public/images/photos/banner-button-bg-hover.png");
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.col-12 {
  margin-top: 12px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  width: 42px;
  height: 19px;
}

.slider.round:before {
  border-radius: 50%;
}

.login-form-label {
  color: #fff;
}
.error-msg {
  color: #f00;
}
.fit-image {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.fit-image .input-image {
  margin-left: 50px;
}
.fit-image img {
  height: 70px;
  margin: 20px 65px;
}
img {
  height: 70px;
}
video {
  height: 70px;
}
.fit-image video {
  height: 70px;
  margin: 20px 10px;
}

.card-box {
  position: relative;
  color: #fff;
  padding: 20px 10px 40px;
  margin: 20px 0px;
}
.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.card-box:hover .icon i {
  font-size: 100px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  padding: 5px 10px 0 10px;
}
.card-box h3 {
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  font-size: 15px;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 5px;
  right: 5px;
  z-index: 0;
  font-size: 72px;
  color: rgba(0, 0, 0, 0.15);
}
.card-box .card-box-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}
.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
.bg-blue {
  background-color: #00c0ef !important;
}
.bg-green {
  background-color: #00a65a !important;
}
.bg-orange {
  background-color: #f39c12 !important;
}
.bg-red {
  background-color: #d9534f !important;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.common-btn {
  display: flex;
  justify-content: center;
  font-family: "Roboto Bold";
  text-transform: uppercase;
  border: 0px;
  transition: all 0.3s ease-out 0s;
  color: #fff !important;
}

.gradient-btn {
  color: #fff;
  background-image: linear-gradient(
    to top,
    #4e63ff,
    #5253f3,
    #5641e6,
    #5a2dd9,
    #5e0ecb
  );
  position: relative;
}

.assign {
  border: 1px solid #eee;
  border-collapse: collapse;
}
.assign td {
  border: 1px solid #b2b2b2;
  padding: 10px;
}
.assign td.header {
  background-color: lightblue;
  color: black;
}

.btn.sidebar-dropdown {
  border: 0px;
  width: 100%;
  background: #222e3c;
  border-left: 3px solid transparent;
  color: rgba(233, 236, 239, 0.5);
  display: block;
  font-weight: 400;
  padding: 0.625rem 1.625rem;
  position: relative;
  text-decoration: none;
  transition: background 0.1s ease-in-out;
  text-align: left;
  border-radius: 0px;
}

.btn.sidebar-dropdown.show {
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.088) 50%,
    transparent
  );
  border-left-color: #3b7ddd;
  color: #e9ecef;
}

.btn.sidebar-dropdown svg {
  margin-right: 12px;
}

.btn.sidebar-dropdown:hover {
  color: rgba(233, 236, 239, 0.75);
}

.btn.sidebar-dropdown:focus {
  box-shadow: none;
  outline: none;
}

.menu-dropdown .dropdown-menu {
  position: relative !important;
  background: #161e27;
  border: 0px;
  padding: 5px 10px 5px 30px;
  transform: none !important;
  width: 100%;
  margin: 0 auto !important;
}

.menu-dropdown .dropdown-item {
  color: rgba(233, 236, 239, 0.5);
  padding: 5px;
}

.menu-dropdown .dropdown-item:hover {
  color: rgba(233, 236, 239, 0.75);
  background-color: transparent;
}

.menu-dropdown .dropdown-item svg {
  margin-right: 12px;
}

.col-auto.d-sm-block.data {
  display: flex !important;
}

.react-datepicker-wrapper {
  display: flex !important;
  padding: 0;
  border: 0;
  width: 100%;
  margin-right: 10px;
}

button.search-button {
  height: 30px;
  margin-top: 20px;
  padding: 0px 25px;
}

.input_file {
  background-color: #ededed;
  border-radius: 29px;
  color: #000;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  min-width: 183px;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.edit-file {
  cursor: pointer;
  left: 0;
  opacity: 0;
  padding-top: 40px;
  position: absolute;
  right: 0;
  top: 0;
}

.videocard_left {
  width: 65%;
  font-size: 0;
}
.videocard_left .video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.videocard_right {
  width: 55%;
  padding-left: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pass-show-icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}

.pass-show-icons {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 4px;
}

.pass-show-login {
  position: absolute;
  right: 52px;
  top: 65%;
  transform: translateY(-50%);
  cursor: pointer;
}

.sidebar{
  width: 260px;
}

.sidebar-content{
  width: 260px;
  overflow-y: auto;
}

.common-popup-wrapper .fit-image{
  justify-content: space-between;
}

.common-popup-wrapper .fit-image img,
.common-popup-wrapper .fit-image video{
  margin: 20px 10px;
}

.modal .modal-body textarea{
  width: 100%;
}

video.video-show {
  height: 50%;
  width: 50%;
  vertical-align: middle;
}

img.video-show {
  height: 20%;
  width: 20%;
}
.password-conatiner,
.password-wrapper{
  position: relative;
}

.password-wrapper .form-control.is-invalid {
  background-position: right 30px top 6px;
}